// These components should be move into correct generic component.
// do not write tests for this file!

import styled from 'styled-components';

type SectionHeaderTypes = {
  color?: string;
};

export const SectionHeader = styled.h2<SectionHeaderTypes>`
  margin: 0 0 32px;
  position: relative;
  font-size: 20px;
  padding-bottom: 20px;
  color: ${(props) => (props.color ? props.color : props.theme.colors.white())};
  &::after {
    content: '';
    position: absolute;
    height: 4px;
    width: 152px;
    bottom: 0;
    left: 0;
    background-color: #008eaa;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    font-size: 28px;
  }
`;

export const SectionText = styled.p`
  margin-bottom: 40px;
`;
