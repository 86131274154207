import { Container } from 'components/atomic-atoms/Container';
import { FeedbackSectionAttributes } from 'lib/cms/queries';
import { SectionHeader } from 'components/atomic-atoms/Section';
import { SectionText } from 'components/atomic-atoms/Section';
import theme from 'components/atomic-atoms/theme';
import { NdsButton } from '@nds/react';

const fallbackData = {
  header: 'Be involved in the development portal',
  text: `Our goal is to offer a self-service developer experience where you can discover, 
  explore and use UC APIs. To create a great developer experience we need input and feedback 
  from the users of this site. What is working, what is not working, what do you miss and what 
  can you live without? Please let us know so that we together can make product development 
  move faster.`,
  buttonText: 'Give Feedback',
};

export const FeedbackSection = ({ content }: { content: FeedbackSectionAttributes }) => {
  return (
    <div className="container mx-auto flex flex-col justify-between gap-10 p-4 py-20 xl:flex-row">
      <Container $flexDirection="column" className="feedback-content">
        <SectionHeader color={theme.colors.black()}>{content?.header ?? fallbackData.header}</SectionHeader>
        <SectionText>{content?.text ?? fallbackData.text}</SectionText>
        <div>
          <NdsButton size={'large'} variant={'secondary'} as="a" href={'mailto:integration@uc.se'}>
            {content?.feedbackButtonText ?? fallbackData.buttonText}
          </NdsButton>
        </div>
      </Container>
      <div className="flex w-full items-center justify-center">
        <img src="/img/feedback.png" alt="Example code" />
      </div>
    </div>
  );
};
