import {
  FeedbackSectionQueryResponse,
  HeroSectionQueryResponse,
  StartPageApiProducts,
  feedbackSectionQuery,
  heroSectionQuery,
} from 'lib/cms/queries';

import { APIProductCards } from 'components/atomic-organisms/api-products-cards';
import { ApiProduct } from 'lib/cms/queries';
import { FeedbackSection } from 'components/atomic-sections/feedback-section';
import { HeroSection } from 'components/atomic-sections/hero-section';
import { useGlobalStore } from 'lib/store';
import useSWR from 'swr';
import { NdsButton } from '@nds/react';
import Link from 'next/link';

type HeroContentProps = {
  apiButtonText: string;
  apiProducts: ApiProduct[];
};

const HeroContent = ({ apiButtonText, apiProducts }: HeroContentProps) => {
  const APISectionButton = () => {
    return (
      <div className="mt-10 flex justify-center">
        <Link href={'/api-products'} passHref legacyBehavior>
          <NdsButton as="a" variant={'primary'}>
            <div className="flex items-center gap-2">{apiButtonText}</div>
          </NdsButton>
        </Link>
      </div>
    );
  };

  return (
    <>
      <APIProductCards apiProducts={apiProducts} startPage />
      <APISectionButton />
    </>
  );
};

export default function Home() {
  const locale = useGlobalStore((state) => state.locale);
  const localizationVariable = { locale };
  const { data: startPageApisResponse } = useSWR<StartPageApiProducts>({ endpoint: `/api/get-start-page-apis/${locale}` });
  const { data: heroSectionResponse } = useSWR<HeroSectionQueryResponse>({
    endpoint: '/api/cms',
    query: heroSectionQuery,
    variables: localizationVariable,
  });
  const { data: feedbackSectionResponse } = useSWR<FeedbackSectionQueryResponse>({
    endpoint: '/api/cms',
    query: feedbackSectionQuery,
    variables: localizationVariable,
  });

  if (!heroSectionResponse || !feedbackSectionResponse || !startPageApisResponse) return null;

  const heroContent = heroSectionResponse.heroSection.data.attributes;
  const feedbackContent = feedbackSectionResponse.feedbackSection.data.attributes;
  const startPageApis = startPageApisResponse.startPageApis;

  return (
    <div>
      <HeroSection title={heroContent.header} text={heroContent.text}>
        <HeroContent apiButtonText={heroContent.apiProductsButtonText} apiProducts={startPageApis} />
      </HeroSection>
      <FeedbackSection content={feedbackContent} />
    </div>
  );
}
